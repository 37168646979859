<template>
  <div>
    <algolia-select
      v-model="queryString"
      :config="algoliaConfig"
      index="sites"
      type="text"
      placeholder="Search sites, software, author..."
      class="fxd-algolia-input"
      icon="algolia"
      icon-pack="fab"
      @focusedselected="onFocusedSelected"
      @fallback-to-firebase="onFallback"
      @movedown="scrollIntoView"
      @moveup="scrollIntoView"
    >
      <template
        slot="list"
        slot-scope="{ results, hits, loading, processing, loadMore, focused }"
      >
        <loading v-if="loading" centered />
        <no-results
          v-else-if="!$_.isEmpty(results) && !$_.get(results, '[0].nbHits')"
          icon="algolia"
          icon-pack="fab"
          title="No sites found"
          class="is-vertically-centered"
        >
          <span slot="message" class="is-size-7 algolia-highlight"
            >We couldn't find any sites<br />
            matching the query '{{ queryString }}'
          </span>
        </no-results>

        <div v-else>
          <list-card
            v-for="hit in hits"
            ref="cards"
            :key="hit.objectID"
            :route="routeFunc(hit)"
            :title="hit.fqdn"
            :subtitle-dark="`Software: ${hit.software}`"
            :label="hit.isDeleted ? 'deleted' : 'active'"
            :timestamp="$moment.unix(hit.dateCreated_timestamp).toDate()"
            :class="
              focused && focused.objectID === hit.objectID ? 'focused' : null
            "
            @click.native="$emit('select', hit)"
          >
            <strong slot="title">
              <algolia-highlight field="fqdn" :hit="hit" />
            </strong>

            <p
              slot="subtitle-dark"
              class="is-size-7 has-overflow-ellipsis-right"
            >
              <span> Software: </span>
              <algolia-highlight field="software" :hit="hit" />
            </p>

            <p
              slot="subtitle-grey"
              class="is-size-7 has-text-grey has-overflow-ellipsis-right"
            >
              <span>
                Email: <algolia-highlight field="authorEmail" :hit="hit" />
              </span>
            </p>
          </list-card>

          <load-more
            v-if="!loading && !$_.get(results, '[0].isComplete', true)"
            :loading="processing"
            @loadMore="loadMore"
          />
        </div>
      </template>
    </algolia-select>
    <slot name="nosearch" v-bind="{ isEmpty: $_.isEmpty(queryString) }" />
  </div>
</template>

<script>
import { getScrollParent, isInViewport } from "@src/services/htmlElemUtils.js";

export default {
  name: "AlgoliaSiteList",
  components: {
    "algolia-select": () => import("@shared/algolia/_algoliaSelect.vue"),
    "algolia-highlight": () => import("@shared/algolia/_algoliaHighlight")
  },
  props: {
    siteFilter: {
      type: String,
      default: ""
    },
    routeFunc: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      queryString: "",
      params: {
        highlightPreTag: "<span class='match'>",
        highlightPostTag: "</span>",
        hitsPerPage: 20
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    algoliaConfig() {
      const query = {
        indexName: "sites",
        query: this.queryString,
        params: this.params
      };

      switch (this.siteFilter) {
        case "all":
          return [query];
        case "subscribed":
          return [{ ...query, filters: `isSubscribed = 1` }];
        case "my":
          return [{ ...query, filters: `agents:${this.userId}` }];
      }

      return [query];
    },
    scrollContainer() {
      return getScrollParent(this.$el);
    }
  },
  methods: {
    scrollIntoView() {
      if (!this.$refs.cards || !this.scrollContainer) return;

      const focused = this.$refs.cards.find(i =>
        this.$_.includes(i.$el.classList, "focused")
      );

      if (focused && !isInViewport(this.scrollContainer, focused.$el, false)) {
        focused.$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    },
    onFallback() {
      this.queryString = "";
    },
    onFocusedSelected(hit) {
      if (hit && this.$_.get(this.$route, "params.siteId") !== hit.siteId) {
        this.$router.push(this.routeFunc(hit));
      }
    }
  }
};
</script>

<style lang="scss">
.algolia-highlight {
  .match {
    font-style: normal;
    background-color: #ffff00a8;
    padding: 0.25rem 0 0.25rem 0.25rem;
  }
}
.is-vertically-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
